html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
}
